<template>
  <footer class="grow bg-gray-1000">
    <section class="border-t border-gray-300 bg-gray-50 p-4 sm:p-6 lg:p-10">
      <div class="mx-auto max-w-screen-xl">
        <div class="flex flex-col lg:flex-row-reverse">
          <div class="w-full lg:mt-0 lg:max-w-lg">
            <div class="py-1 text-xl font-bold text-gray-900">
              Om Skaffa Hund
            </div>
            <p class="mt-2 text-sm text-gray-700 sm:text-base">
              Skaffa Hund erbjuder en omfattande plattform där du kan söka bland hundratals hundar för att hitta den som passar just dig och din livsstil perfekt.
              <br><br>
              Vi förser dig med all nödvändig information och resurser för att göra processen att köpa eller sälja hund så enkel och problemfri som möjligt. Från grundläggande råd till avancerad kunskap om hundvård, beteende och träning – vi har allt samlat på ett och samma ställe. Låt Skaffa Hund vara din guide i jakten på din framtida hundkompis, och ta del av vårt community fyllt med likasinnade hundälskare idag!
            </p>
            
            <div class="mt-3 flex">
              <apple-reviews-card />
            </div>
          </div>

          <div class="mt-6 grow lg:mt-0">
            <div class="py-1 text-xl font-bold text-gray-900">
              Populära sökningar
            </div>

            <div class="mt-2 grid grid-cols-2 gap-6 sm:grid-cols-4 lg:mt-4">
              <div>
                <div class="font-bold uppercase text-gray-800">
                  Raser
                </div>
                
                <div v-for="breed in breeds" :key="breed.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.breeds', { slug: breed.value })">
                    {{ breed.label }}
                  </a>
                </div>
              </div>

              <div>
                <div class="font-bold uppercase text-gray-800">
                  Hundstorlek
                </div>
                <div v-for="size in sizes" :key="size.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.index', { size: size.value })">
                    {{ size.label }}
                  </a>
                </div>
              </div>
              
              <div>
                <div class="font-bold uppercase text-gray-800">
                  Kön & Ålder
                </div>
                <div v-for="gender in genders" :key="gender.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.index', { gender: gender.value })">
                    {{ gender.label }}
                  </a>
                </div>
                
                <div v-for="age in ages" :key="age.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.index', { age: age.value })">
                    {{ age.label }}
                  </a>
                </div>
              </div>
              
              <div>
                <div class="font-bold uppercase text-gray-800">
                  Säljare
                </div>
                <div v-for="seller in sellers" :key="seller.value" class="font-hairline truncate pr-1 leading-loose text-gray-700">
                  <a class="hover:underline" :href="window.route('ads.index', { seller: seller.value })">
                    {{ seller.label }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-gray-1000 p-4 sm:p-6 lg:p-10">
      <div class="mx-auto max-w-screen-xl">
        <div class="grid grid-cols-2 gap-y-4 md:grid-cols-4 md:gap-0 lg:grid-cols-5">
          <a :href="window.route('home')" class="hidden lg:block">
            <img
              class="h-10 w-auto sm:h-12"
              src="@assets/Logo-Green-Horizontal-Variant.svg"
              alt="Skaffa Hund Logo"
              loading="lazy"
            >
          </a>

          <div>
            <div class="font-bold uppercase text-gray-200">
              Köp
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('keywordPages.buyDog')">
                Köpa hund: Alla steg
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('information.buyer') + '#premium'">
                Premium
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('ownershipCost.show')" title="Vad kostar det att ha en hund?">
                Kostnadskalkylator
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'overvager-du-hund' })">
                Överväger du hund?
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'hitta-ratt-hund' })">
                Hitta rätt hund
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'upphamtning-och-kop' })">
                Upphämtning och köp
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'du-och-din-hund' })">
                Du och din hund
              </a>
            </div>
          </div>

          <div>
            <div class="font-bold uppercase text-gray-200">
              Sälj
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('information.seller')">
                Introduktion
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('kennels.create')">
                Sälj som kennel
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('insurances.create')">
                Kullförsäkring
                <span class="rounded-full bg-yellow-400 px-1.5 py-0.5 text-xs font-bold uppercase text-yellow-800">Nyhet</span>
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'din-forsta-forsaljning' })">
                Din första försäljning
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('tags.show', { tag: 'avel-och-parning' })">
                Avel och parning
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('information.safetyPack')">
                Trygghetspaketet
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('information.safePayments')">
                Säker Betalning
              </a>
            </div>
          </div>

          <div>
            <div class="font-bold uppercase text-gray-200">
              Hitta
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('kennels.map')">
                Kennlar
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('breeds.index')">
                Hundraser
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('quizzes.index')">
                Kunskapstest
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('breedGroups.index')">
                Rasgrupper
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('breedGuideResults.create')">
                Rasguiden
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('insuranceGuide.show')">
                Försäkringsguiden
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('shop')">
                Butiken
              </a>
            </div>
          </div>

          <div>
            <div class="font-bold uppercase text-gray-200">
              Information
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('about')">
                Om oss
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('help')">
                Vanliga frågor
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('contact')">
                Kontakta oss
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('partners')">
                Samarbetspartners
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('dogCouncil')">
                Hundrådet
              </a>
            </div>
            <div class="font-hairline truncate pr-1 leading-loose text-gray-500">
              <a class="hover:underline" :href="window.route('blogs.show')">
                Bloggtoppen
              </a>
            </div>
          </div>
        </div>

        <div class="-mx-4 mt-12 justify-between sm:mx-0 sm:flex md:mt-24 lg:mt-32">
          <div>
            <div class="flex items-center justify-center md:justify-start">
              <a rel="nofollow" :href="window.route('terms')" class="font-hairline text-xs text-gray-500 hover:underline sm:text-sm">
                Användarvillkor
              </a>
              <span class="mx-1 text-gray-600">&middot;</span>
              <a rel="nofollow" :href="window.route('privacyPolicy')" class="font-hairline text-xs text-gray-500 hover:underline sm:text-sm">
                Personuppgiftshantering
              </a>
              <span class="mx-1 text-gray-600">&middot;</span>
              <a rel="nofollow" :href="window.route('cookiePolicy')" class="font-hairline text-xs text-gray-500 hover:underline sm:text-sm">
                Cookie policy
              </a>
            </div>

            <div class="font-hairline text-center text-sm text-gray-600 sm:text-left">
              © Copyright {{ year }} Dogs Unleashed AB. <br class="md:hidden"> Org.nr.: 559049-6807
            </div>
          </div>

          <div class="mt-6 flex items-center justify-center space-x-2 sm:mt-0">
            <a href="https://www.facebook.com/SkaffaHund.se/" class="rounded bg-blue-600 px-1 text-xl text-white" title="Skaffa Hund på Facebook"><fa-icon icon="facebook" type="fab" /></a>
            <a href="https://instagram.com/skaffahund/" class="rounded bg-purple-500 px-1 text-xl text-white" title="Skaffa Hund på Instagram"><fa-icon icon="instagram" type="fab" /></a>

            <a href="https://apps.apple.com/se/app/skaffa-hund/id1476715046" target="_blank" rel="noopener">
              <img
                class="h-8 w-auto"
                src="@assets/App-Store.svg"
                alt="App Store Link"
                loading="lazy"
              >
            </a>

            <a href="https://play.google.com/store/apps/details?id=se.skaffahund" target="_blank" rel="noopener">
              <img
                class="h-8 w-auto"
                src="@assets/Google-Play.svg"
                alt="Google Play Link"
                loading="lazy"
              >
            </a>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { DogAge, Gender, Seller } from "@/@types";
  import { enumOptions } from "@/library/enums";
  import AppleReviewsCard from "@/components/AppleReviewsCard.vue";

  export default defineComponent({
    components: { AppleReviewsCard },
    computed: {
      Seller() {
        return Seller
      },
      Gender() {
        return Gender
      },
      DogAge() {
        return DogAge
      },
      year() {
        return new Date().getFullYear();
      },
      breeds() {
        return [
          { value: 'labrador-retriever', label: 'Labrador Retriever' },
          { value: 'tysk-schaferhund', label: 'Tysk Schäferhund' },
          { value: 'golden-retriever', label: 'Golden Retriever' },
          { value: 'jamthund', label: 'Jämthund' },
          { value: 'cocker-spaniel', label: 'Cocker Spaniel' },
        ]
      },
      sellers() {
        return enumOptions('Seller').filter(o => o.value !== Seller.Organization)
      },
      sizes() {
        return enumOptions('Size');
      },
      genders() {
        return enumOptions('Gender');
      },
      ages() {
        return enumOptions('DogAge');
      },
    },
  })
</script>
