<template>
  <user-edit-modal
    v-if="showModal"
    :initial-open="true"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserEditModal from "@/components/UserEditModal.vue";
import userStore from "@/store/user-store";

export default defineComponent({
  components: { UserEditModal },
  data() {
    return {
      showModal: false,
    }
  },
  mounted() {
    const url = new URL(window.location.href);
    this.showModal = userStore.isLogged() 
      && url.searchParams.has('wasRegistered') 
      && url.searchParams.get('wasRegistered') === "true";
  },
})
</script>
