import {reactive, readonly} from 'vue';
import Errors from "@/library/errors";

export default abstract class Store<T extends Object> {
  protected state: T;

  constructor(data: T) {
    this.state = reactive(data) as T;
  }

  public getState(): T {
    return readonly(this.state) as T
  }

  public getFormErrors(): Errors {
    return (this.state as IObjectKeys).form.errors;
  }
}
