<template>
  <div class="relative size-full">
    <webp-image
      :src="src"
      :alt="alt"
      :img-class="imgClass + ' cursor-zoom-in hover:opacity-75'"
      @click="isExpanded = true"
    />
    
    <p v-if="caption" class="pointer-events-none absolute bottom-0 left-0 z-10 w-full bg-white/75 p-1 text-xs text-black">
      <span class="truncate-2-lines">
        {{ caption }}
      </span>
    </p>
  </div>

  <teleport to="#modals">
    <transition name="fade-scale">
      <div v-if="isExpanded" class="fixed inset-0 z-50 flex cursor-zoom-out items-center justify-center">
        <div class="fixed inset-0 scale-150 bg-gray-900/90" @click="isExpanded = false" />

        <div class="relative z-10 flex w-full flex-col items-center lg:p-10" @click="isExpanded = false">
          <img :src="fullscreen ?? src" class="size-auto object-contain lg:max-h-[80vh]">
          
          <p v-if="isExpanded && caption" class="max-w-screen-lg p-3 text-white">
            {{ caption }}
          </p>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WebpImage from "@/components/WebpImage.vue";

export default defineComponent({
  components: { WebpImage },
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    imgClass: {
      type: String,
      default: null,
    },
    fullscreen: {
      type: String,
      default: null,
    },
    caption: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  mounted() {
    window.addEventListener('keydown', this.keyHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.keyHandler);
  },
  methods: {
    keyHandler(event: KeyboardEvent) {
      if (event.code == "Escape") {
        this.isExpanded = false;
      }
    },
  },
});
</script>
