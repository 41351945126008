import { some } from "lodash-es";

export default class Errors {
  jar: IObjectKeys;

  constructor() {
    this.jar = {};
  }

  has(fields: string|string[]): boolean {
    if (typeof fields === "string") {
      return this.jar.hasOwnProperty(fields);
    } else {
      return some(fields, field => this.jar.hasOwnProperty(field));
    }
  }

  any(): boolean {
    return Object.keys(this.jar).length > 0;
  }

  get(fields: string|string[]): string|null {
    if (typeof fields === "string") {
      return this.getErrorForField(fields);
    } else {
      let error: string|null = null;

      fields.forEach(field => {
        const temp = this.getErrorForField(field);
        if (!error && temp) {
          error = temp;
        }
      })

      return error;
    }
  }

  private getErrorForField(field: string): string|null {
    return this.has(field)
      ? this.jar[field][0]
      : null;
  }

  record(errors: IObjectKeys): void {
    if (errors.hasOwnProperty('errors')) {
      this.jar = errors.errors;
    } else if (errors.hasOwnProperty('message')) {
      this.jar = { any: [errors.message] }
    }
  }

  clear(fieldName: string|null = null): void {
    if (! fieldName) {
      this.jar = {};
      return;
    }

    if (! fieldName.includes('*')) {
      delete this.jar[fieldName];
      return;
    }
    
    const [field,] = fieldName.split('*');
    
    for (const key in this.jar) {
      if (key.includes(field)) {
        delete this.jar[key];
      }
    }
  }

  allAsText(): string {
    let errors = '';

    for (const field in this.jar) {
      errors += this.getErrorForField(field) + ' ';
    }

    return errors
  }
}
