<template>
  <div class="rounded bg-white p-1.5 shadow-md sm:rounded-lg sm:p-3">
    <div class="flex items-center space-x-1.5 sm:space-x-3">
      <img src="@assets/App-Store.png" class="size-6 sm:size-14">

      <div class="flex items-center space-x-1.5 sm:block sm:space-x-0 sm:space-y-1.5">
        <div class="flex items-center space-x-0.5 text-sm sm:space-x-1">
          <span
            v-for="n in 5"
            :key="n"
            class="flex size-5 items-center justify-center bg-yellow-400 p-1 sm:size-7 sm:p-1.5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="size-full fill-current text-yellow-700"
            >
              <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
            </svg>
          </span>
        </div>

        <div class="pr-0.5 text-xs text-black">
          <strong>4.7</strong> i betyg <span class="mx-0.5">&middot;</span> <strong>800+</strong> omdömen
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  //
});
</script>
