<template>
  <span
    v-if="$slots.trigger"
    class="shrink-0"
    :class="triggerClass"
    @click="openModal"
  ><slot name="trigger" /></span>

  <teleport to="#modals">
    <transition name="fade-scale" appear>
      <div v-if="isOpen" class="fixed inset-0 z-50 flex h-screen w-screen flex-col items-center justify-center p-2">
        <div class="fixed inset-0 scale-150 bg-gray-500/75" @click="clickOutside" />

        <div 
          class="relative z-10 flex w-full flex-col overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
        >
          <div class="flex-1 overflow-scroll overscroll-none bg-white px-3 pt-3 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                v-if="showImage"
                class="mx-auto mt-3 flex size-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:mr-4 sm:mt-0 sm:size-10"
              >
                <slot name="image" />
              </div>

              <div
                v-else-if="showIcon"
                class="mx-auto mt-3 flex size-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:mr-4 sm:mt-0 sm:size-10"
                :class="{ 'bg-red-100 text-red-600': type === ModalType.Danger, 'bg-green-100 text-green-600': type === ModalType.Success }"
              >
                <fa-icon :icon="icon ?? ''" />
              </div>

              <div class="-mx-2 flex-1 px-2 pb-3 text-gray-700 md:max-h-2xl">
                <h3 v-if="title" class="text-center text-xl font-medium leading-snug text-black sm:text-left" :class="{ 'mb-2': ! subtitle }">
                  {{ title }}
                </h3>
                <h4 v-if="subtitle" class="mb-2 text-center leading-snug text-gray-600 sm:text-left">
                  {{ subtitle }}
                </h4>
                <p v-if="text" class="text-center sm:text-left">
                  {{ text }}
                </p>

                <slot :action-clicked="actionClicked" />
              </div>
            </div>
          </div>

          <div v-if="showButtons" class="relative shrink-0 bg-gray-100 p-3 sm:flex sm:flex-row-reverse sm:px-6">
            <transition name="fade-in">
              <div v-if="errors.any()" class="absolute bottom-0 right-0 mt-2 rounded bg-red-50 px-2 py-1 text-sm font-medium text-red-500">
                {{ errors.allAsText() }}
              </div>
            </transition>

            <button
              v-if="hasAction"
              type="submit"
              class="mr-2 py-2 sm:ml-2 sm:mr-0"
              :class="{ 'btn-gray': type === ModalType.None, 'btn-red': type === ModalType.Danger, 'btn-green': type === ModalType.Success }"
              :disabled="callbackLoading || isProcessing"
              form="modalForm"
              @click="actionClicked"
            >
              {{ actionText }}
            </button>
            <button
              v-if="showCancel"
              type="button"
              class="btn-white py-2"
              @click="close"
            >
              {{ cancelText ?? 'Okej' }}
            </button>
          </div>
        </div>
        
        <div class="svh-height shrink-0" />
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { ModalType } from "@/@types";
  import Errors from "@/library/errors";

  export default defineComponent({
    props: {
      open: {
        type: Boolean,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
      subtitle: {
        type: String,
        default: null,
      },
      text: {
        type: String,
        default: null,
      },
      type: {
        type: Number as PropType<ModalType>,
        default: ModalType.None,
      },
      actionText: {
        type: String,
        default: 'Spara',
      },
      callback: {
        type: Function as PropType<() => Promise<void>|void>,
        default: null,
      },
      cancelText: {
        type: String,
        default: 'Avbryt',
      },
      recordErrors: {
        type: Boolean,
        default: true,
      },
      closeOnSuccess: {
        type: Boolean,
        default: true,
      },
      showButtons: {
        type: Boolean,
        default: true,
      },
      triggerClass: {
        type: String,
        default: '',
      },
      isProcessing: {
        type: Boolean,
        default: false,
      },
      initialOpen: {
        type: Boolean,
        default: false,
      },
      shouldCloseOnClickOutside: {
        type: Boolean,
        default: false,
      },
      forceShowIcon: {
        type: Boolean,
        default: false,
      },
      showCancel: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['close'],
    data() {
      return {
        ModalType,
        tryToOpen: this.initialOpen,
        callbackLoading: false,
        errors: new Errors
      };
    },
    computed: {
      hasType(): boolean {
        return this.type !== ModalType.None;
      },

      hasAction(): boolean {
        return !! this.callback;
      },

      showIcon(): boolean {
        return this.hasType
          && (this.type !== ModalType.Success || ! this.hasAction || this.forceShowIcon);
      },

      showImage(): boolean {
        return !! this.$slots.image;
      },

      icon(): string|null {
        if (this.type === ModalType.Danger) {
          return 'exclamation-triangle'
        } else if (this.type === ModalType.Success) {
          return 'check'
        } else {
          return null;
        }
      },

      isOpen(): boolean {
        return this.open === null ? this.tryToOpen : this.open;
      },
    },
    methods: {
      openModal() {
        this.tryToOpen = true;
      },
      
      clickOutside(): void {
        (this.showIcon || this.shouldCloseOnClickOutside) && this.close();
      },

      close(): void {
        this.$emit('close');
        this.tryToOpen = false;
      },

      actionClicked(): void {
        this.callbackLoading = true;

        this.callback()
          .then(() => {
            if (this.closeOnSuccess) {
              this.close()
            }
          })
          .catch((error: any) => {
            console.log(error);

            if (this.recordErrors) {
              this.recordErrorsAndClear(error);
            }
          })
          .finally(() => {
            this.callbackLoading = false;
          });
      },

      recordErrorsAndClear(errors: IObjectKeys): void {
        this.errors.record(errors);

        setTimeout(() => {
          this.errors.clear();
        }, 5000)
      }
    },
  });
</script>

<style lang="scss" scoped>
.svh-height {
  width: 100%;
  height: 0;
  
  @supports (height: 100svh) {
    height: 100%;
    max-height: calc(100vh - 100svh);
  }
}
</style>
