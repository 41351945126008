const loadedScripts = [] as string[];

export function loadScript(src: string, callback: Function|null = null, onlyOnce = false): void {
  if (loadedScripts.includes(src) && onlyOnce) return;

  loadedScripts.push(src);

  const script = document.createElement('script');
  script.setAttribute('src', src);

  if (callback) {
    script.addEventListener('load', callback as any);
  }

  document.head.appendChild(script);
}
