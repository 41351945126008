<template>
  <section>
    <newsletter-form />
  </section>
  
  <section class="bg-gray-1000 p-4 sm:p-6 lg:p-10">
    <div class="max-w-screen-xl mx-auto">
      <div class="grid grid-cols-2 gap-y-4 md:grid-cols-4 lg:grid-cols-5 md:gap-0">
        <div v-for="(category, n) in categories.slice(0, 4)" :key="category.id" :class="{ 'hidden lg:block': n === 3 }">
          <div class="font-bold text-gray-200 uppercase">{{ category.name }}</div>
          <div
            v-for="child in category.children"
            :key="child.id"
            class="truncate font-hairline text-gray-500 leading-loose pr-1"
          >
            <a class="hover:underline" :href="window.route('shopCategories.show', { category: child.slug })">{{ child.name }}</a>
          </div>
        </div>

        <div>
          <div class="font-bold text-gray-200 uppercase">Information</div>
          <div class="truncate font-hairline text-gray-500 leading-loose pr-1"><a class="hover:underline" :href="window.route('help')" target="_blank">Vanliga frågor</a></div>
          <div class="truncate font-hairline text-gray-500 leading-loose pr-1"><a class="hover:underline" :href="window.route('about')" target="_blank">Om oss</a></div>
          <div class="truncate font-hairline text-gray-500 leading-loose pr-1"><a class="hover:underline" :href="window.route('contact')" target="_blank">Kontakta oss</a></div>
          <div class="truncate font-hairline text-gray-500 leading-loose pr-1"><a class="hover:underline" href="https://reklam.skaffahund.se" target="_blank">Marknadsföring</a></div>
          
          <a :href="window.route('home')" class="block mt-6">
            <img class="h-10 sm:h-12 w-auto" src="@assets/Logo-Green-Horizontal-Variant.svg" alt="Skaffa Hund Logo" loading="lazy">
          </a>
        </div>
      </div>

      <hr class="border-t-2 border-gray-800 mt-12 mb-6">

      <div class="sm:flex justify-between -mx-4 sm:mx-0">
        <div>
          <div class="flex justify-center items-center md:justify-start">
            <a :href="window.route('terms')" class="hover:underline text-xs sm:text-sm font-hairline text-gray-500">Användarvillkor</a>
            <span class="mx-1 text-gray-600">&middot;</span>
            <a :href="window.route('privacyPolicy')" class="hover:underline text-xs sm:text-sm font-hairline text-gray-500">Personuppgiftshantering</a>
            <span class="mx-1 text-gray-600">&middot;</span>
            <a :href="window.route('cookiePolicy')" class="hover:underline text-xs sm:text-sm font-hairline text-gray-500">Cookie policy</a>
          </div>

          <div class="text-center sm:text-left text-gray-600 text-sm font-hairline">
            © Copyright {{ year }} Dogs Unleashed AB. <br class="md:hidden"> Org.nr.: 559049-6807
          </div>
        </div>

        <div class="mt-6 sm:mt-0 flex justify-center items-center space-x-2">
          <a href="https://www.facebook.com/SkaffaHund.se/" class="text-xl text-white bg-blue-600 px-1 rounded" title="Skaffa Hund på Facebook"><fa-icon icon="facebook" type="fab" /></a>
          <a href="https://instagram.com/skaffahund/" class="text-xl text-white bg-purple-500 px-1 rounded" title="Skaffa Hund på Instagram"><fa-icon icon="instagram" type="fab" /></a>

          <a href="https://apps.apple.com/se/app/skaffa-hund/id1476715046" target="_blank" rel="noopener">
            <img
              class="h-8 w-auto"
              src="@assets/App-Store.svg"
              alt="App Store Link"
              loading="lazy"
            >
          </a>

          <a href="https://play.google.com/store/apps/details?id=se.skaffahund" target="_blank" rel="noopener">
            <img
              class="h-8 w-auto"
              src="@assets/Google-Play.svg"
              alt="Google Play Link"
              loading="lazy"
            >
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import shopStore from "@/store/shop-store";
import NewsletterForm from "@/components/Shop/NewsletterForm.vue";

export default defineComponent({
  components: { NewsletterForm },
  computed: {
    categories() {
      return shopStore.getState().categories;
    },
    
    year() {
      return new Date().getFullYear();
    },
  },
})
</script>
