<template>
  <div ref="header" class="sticky left-0 top-0 z-70 w-full bg-green-600 lg:z-100">
    <nav class="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-2">
      <div class="order-1 flex items-center md:mr-6">
        <a :href="window.route('home')" class="mr-1.5 rounded p-1 hover:bg-smoke-10">
          <img class="mr-3 h-8 w-auto" src="@assets/Logo-White-Horizontal-Variant.svg" alt="Skaffa Hund Logotyp">
        </a>
        
        <a :href="window.route('shop')" class="flex items-center rounded-lg bg-yellow-400 px-2.5 py-1 text-sm font-medium text-yellow-900 hover:bg-yellow-500">
          <fa-icon icon="shopping-bag" class="mr-2" /> Butik
        </a>
      </div>
    
      <nav class="order-4 mt-3 hidden w-full justify-center md:flex">
        <menu-category-button v-for="category in categories.slice(0, 7)" :key="category.id" :category="category" />
        
        <button
          id="menu-item-more"
          class="mx-0.5 shrink-0 rounded px-6 py-2 text-sm font-medium text-white hover:bg-green-700 hover:underline md:text-base xl:px-8"
          :class="menuOpen ? 'underline bg-green-700' : 'bg-smoke-10'"
          @click="menuOpen = !menuOpen"
        >
          <fa-icon :icon="'bars'" class="fa-sm pointer-events-none mr-2 text-green-200" />
          Mer
        </button>
      </nav>
      
      <div class="order-3 ml-3 space-x-1.5">
        <a 
          :href="window.route('shop.likes')" 
          class="rounded p-2 text-xl hover:bg-green-700"
          :class="isCurrentRoute('shop.likes') ? 'bg-green-700 text-white' : 'text-green-200 hover:text-white'"
        >
          <fa-icon icon="heart" />
        </a>
        <a
          :href="window.route('shopTrackers.index')"
          class="rounded p-2 text-xl hover:bg-green-700"
          :class="isCurrentRoute('shopTrackers.index') ? 'bg-green-700 text-white' : 'text-green-200 hover:text-white'"
        >
          <fa-icon icon="bell" />
        </a>
        <button class="p-2 text-xl text-green-200 hover:text-white md:hidden" @click.stop="menuOpen = ! menuOpen">
          <fa-icon icon="bars" />
        </button>
      </div>
  
      <search-products />
    </nav>
  </div>
  
  <transition name="fade-scale">
    <div
      v-if="menuOpen"
      v-click-outside="onClickOutside"
      :class="
        `fixed z-80 inset-0 bg-white flex flex-col md:mx-auto md:left-0 md:top-[var(--header-height)] md:block md:w-full md:h-auto md:bottom-auto md:border-b border-smoke-15 md:shadow-xl md:shadow-smoke-10`
      "
    >
      <div class="flex justify-between p-2 md:hidden">
        <a :href="window.route('shop')" class="flex items-center">
          <img class="mr-3 h-8 w-auto" src="@assets/Logo-Green-Horizontal.svg" alt="Skaffa Hund Logotyp">
        
          <div class="flex items-center rounded-lg bg-yellow-400 px-2.5 py-1 text-sm font-medium text-yellow-900">
            <fa-icon icon="shopping-bag" class="mr-2" /> Butik
          </div>
        </a>
        
        <button class="p-2 text-xl text-gray-700 hover:text-black" @click.stop="menuOpen = false">
          <fa-icon icon="times" />
        </button>
      </div>
    
      <div class="shop-full-height flex-1 overflow-y-scroll overscroll-contain md:grid md:grid-cols-4 md:gap-y-12 md:px-4 md:py-8 lg:grid-cols-5 xl:grid-cols-6">
        <shop-category-list-item v-for="category in categories" :key="category.id" :category="category" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import isCurrentRoute from "@/library/is-current-route";
import FaIcon from "@/components/FaIcon.vue";
import MenuCategoryButton from "@/components/Shop/MenuCategoryButton.vue";
import ShopCategoryListItem from "@/components/Shop/ShopCategoryListItem.vue";
import SearchProducts from "@/components/Shop/SearchProducts.vue";
import shopStore from "@/store/shop-store";

export default defineComponent({
  components: { SearchProducts, ShopCategoryListItem, MenuCategoryButton, FaIcon },
  data() {
    return { 
      menuOpen: false,
      currentPage: window.route().current(),
    };
  },
  computed: {
    categories() {
      return shopStore.getState().categories;
    },
  },
  mounted() {
    document.documentElement.style.setProperty('--header-height', `${this.$refs.header.clientHeight}px`)
  },
  methods: {
    isCurrentRoute,

    onClickOutside(event: any) {
      this.menuOpen = event.target.id === 'menu-item-more';
    },
  },
});
</script>
