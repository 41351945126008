import {
  ContestEntryType,
  DogAge,
  FoodProductSpecialty,
  Gender, InsuranceCompany,
  Seller,
  Size,
  TraitAbility
} from '@/@types/enums';
import { map } from "lodash-es"
import { InsuranceStatus } from "@/@types";

export const enumValues = {
  Gender: {
    [Gender.Male]: 'Hane',
    [Gender.Female]: 'Tik',
  },
  Size: {
    [Size.Tiny]: 'Väldigt liten',
    [Size.Small]: 'Liten',
    [Size.Medium]: 'Mellan',
    [Size.Large]: 'Stor',
    [Size.Huge]: 'Väldigt stor',
  },
  TraitAbility: {
    [TraitAbility.VeryLow]: 'Väldigt låg',
    [TraitAbility.Low]: 'Låg',
    [TraitAbility.Medium]: 'Mellan',
    [TraitAbility.High]: 'Hög',
    [TraitAbility.VeryHigh]: 'Väldigt hög',
  },
  Seller: {
    [Seller.Individual]: 'Privatperson',
    [Seller.Kennel]: 'Kennel',
    [Seller.Organization]: 'Organisation',
  },
  DogAge: {
    [DogAge.Puppy]: 'Valp',
    [DogAge.Young]: 'Unghund (+6 mån)',
    [DogAge.Adult]: 'Vuxen (+18 mån)',
  },
  FoodProductSpecialty: {
    [FoodProductSpecialty.None]: 'Inga',
    [FoodProductSpecialty.Joints]: 'Känsliga leder',
    [FoodProductSpecialty.Overweight]: 'Överviktig',
    [FoodProductSpecialty.Digestion]: 'Känslig matsmältning',
    [FoodProductSpecialty.Itching]: 'Irritation och klåda',
    [FoodProductSpecialty.Sterilized]: 'Steriliserad',
  },
  ContestEntryType: {
    [ContestEntryType.Default]: 'Gå med',
    [ContestEntryType.FacebookLike]: 'Följ oss på Facebook',
    [ContestEntryType.InstagramLike]: 'Följ oss på Instagram',
    [ContestEntryType.AppInstallation]: 'Ladda ner vår App och logga in',
    [ContestEntryType.FriendReferral]: 'Bjud in vänner till tävlingen',
  },
  InsuranceCompany: {
    [InsuranceCompany.Ingen]: 'Ingen försäkring',
    [InsuranceCompany.Folksam]: 'Folksam',
    [InsuranceCompany.Svedea]: 'Svedea',
    [InsuranceCompany.TryggHansa]: 'Trygg-Hansa',
    [InsuranceCompany.If]: 'If Skadeförsäkring',
    [InsuranceCompany.Hedvig]: 'Hedvig',
    [InsuranceCompany.ManyPets]: 'ManyPets',
    [InsuranceCompany.Dina]: 'Dina',
    [InsuranceCompany.Lassie]: 'Lassie',
    [InsuranceCompany.ICA]: 'ICA Försäkring',
    [InsuranceCompany.Sveland]: 'Sveland',
    [InsuranceCompany.Agria]: 'Agria',
    [InsuranceCompany.Petson]: 'Petson',
  },
  InsuranceStatus: {
    [InsuranceStatus.Applied]: 'Ansökt',
    [InsuranceStatus.Submitted]: 'Inskickad',
    [InsuranceStatus.Approved]: 'Godkänd',
    [InsuranceStatus.Denied]: 'Nekad',
    [InsuranceStatus.PuppyInformationCollected]: 'Godkänd',
    [InsuranceStatus.PuppyInformationSubmitted]: 'Godkänd',
  },
}

export type enumType = keyof typeof enumValues;

export interface SelectOption {
  value: number,
  label: string,
}

export const enumToString = (type: enumType, value: number|string|null): string => {
  return value !== null && enumValues[type].hasOwnProperty(value)
    ? (enumValues as IObjectKeys)[type][value]
    : '';
}

export const enumOptions = (type: enumType): SelectOption[] => {
  return map(enumValues[type], (value: string, key: string) => {
    return {
      value: parseInt(key),
      label: value,
    };
  });
};
