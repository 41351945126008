<template>
  <transition name="fade">
    <div
      v-if="searchFocused"
      class="fixed z-100 inset-0 bg-green-1000 bg-opacity-70"
      @click="() => searchFocused = false"
    />
  </transition>
  
  <div
    v-click-outside="() => searchFocused = false"
    class="w-full order-3 mt-2 md:order-2 md:mt-0 md:flex-1 flex justify-end"
  >
    <form
      class="relative w-full md:max-w-xl z-100"
      method="get"
      :action="window.route('shopProducts.index')"
      @submit="() => addLatestSearch(form.data.name)"
    >
      <div
        class="flex items-stretch transition-colors duration-200 rounded-md"
        :class="{
          'bg-green-50': !searchFocused,
          'bg-white': searchFocused,
          'rounded-b-none': showResults,
        }"
      >
        <label for="search" class="flex items-center pl-3 md:pl-4">
          <fa-icon icon="search" class="text-green-500 md:text-lg" />
        </label>
    
        <input
          id="search"
          v-model="form.data.name"
          name="name"
          class="border-none bg-transparent text-black w-full md:text-lg p-2 px-3 md:px-4"
          placeholder="Vad letar du efter?"
          autocomplete="off"
          @input="() => search()"
          @focus="searchFocused = true"
        >
      
        <transition name="fade-fast">
          <div
            v-if="searchFocused"
            class="p-1"
          >
            <button
              type="submit"
              class="h-full bg-green-500 text-white rounded-md font-bold px-3"
            >
              Sök
            </button>
          </div>
        </transition>
      </div>
    
      <div 
        v-if="showResults"
        class="absolute top-full left-0 w-full bg-white rounded-b-md shop-full-height overflow-y-scroll overscroll-contain shadow-lg overflow-hidden"
      >
        <div
          v-if="noResults" 
          class="text-center pt-2 pb-3"
        >
          Vi kunde inte hitta några resultat 🙁
        </div>
        
        <a
          v-for="category in filteredCategories"
          :key="category.id"
          :href="window.route('shopCategories.show', { category: category.slug })"
          class="flex items-center p-2 group hover:bg-gray-100"
          @click="() => addLatestSearch(category.name)"
        >
          <div class="h-8 w-8 md:h-10 md:w-10 flex items-center justify-center">
            <fa-icon icon="search" class="text-gray-700" />
          </div>
          
          <div class="flex-1 px-2">
            <div class="text-black text-sm md:text-base group-hover:underline">{{ category.name }}</div>
            <div class="text-gray-600 text-xs">{{ category.parent ? category.parent.name : 'Kategori' }}</div>
          </div>
        </a>
        
        <a
          v-for="manufacturer in filteredManufacturers"
          :key="manufacturer.id"
          :href="window.route('shopManufacturers.show', { manufacturer: manufacturer.slug })"
          class="flex items-center p-2 group hover:bg-gray-100"
          @click="() => addLatestSearch(manufacturer.name)"
        >
          <div class="h-8 w-8 md:h-10 md:w-10 flex items-center justify-center">
            <fa-icon icon="search" class="text-gray-700" />
          </div>
          
          <div class="flex-1 px-2">
            <div class="text-black text-sm md:text-base group-hover:underline" v-html="manufacturer.name" />
            <div class="text-gray-600 text-xs">Märke</div>
          </div>
        </a>
        
        <a
          v-for="product in paginator?.data"
          :key="product.id"
          :href="window.route('shopProducts.show', { product: product.slug })"
          class="flex items-center p-2 group hover:bg-gray-100"
          @click="() => addLatestSearch(product.name)"
        >
          <img :src="product.image_url" :alt="product.name" class="h-8 w-8 md:h-10 md:w-10" loading="lazy">
          
          <div class="flex-1 pl-2 pr-4 overflow-hidden">
            <div class="text-black text-sm md:text-base group-hover:underline truncate text-ellipsis" v-html="product.name" />
            <div class="text-gray-600 text-xs truncate text-ellipsis">{{ product.category_name }}</div>
          </div>
          
          <div class="flex-shrink-0 text-right">
            <div class="font-medium" :class="product.latest_discount > 0 ? 'text-red-600' : 'text-black'">
              {{ formatPrice(product.latest_price, true, 2) }}
            </div>
        
            <div v-if="product.latest_discount > 0" class="ml-2 text-xs text-gray-500 line-through">
              {{ formatPrice(product.default_price, true, 2) }}
            </div>
          </div>
        </a>
        
        <template v-if="latestSearches.length > 0 && form.data.name.length === 0">
          <div class="font-bold pt-2 pb-1 px-4">Senaste sökningar</div>
        
          <a
            v-for="(latestSearch, n) in latestSearches"
            :key="n"
            :href="window.route('shopProducts.index', { name: latestSearch.name })"
            class="flex items-center p-2 group hover:bg-gray-100"
          >
            <div class="h-8 w-8 md:h-10 md:w-10 flex items-center justify-center">
              <fa-icon icon="history" class="text-gray-700" />
            </div>
          
            <div class="flex-1 px-2">
              <div class="text-black text-sm md:text-base group-hover:underline">{{ latestSearch.name }}</div>
              <div class="text-gray-600 text-xs">{{ formatDate(latestSearch.date) }}</div>
            </div>
          </a>
        </template>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import shopStore from '@/store/shop-store';
import { defineComponent } from 'vue';
import Form from "@/library/form";
import { debounce } from "lodash-es";
import { Paginator, ShopCategory, ShopProduct } from '@/@types';
import formatPrice from '@/library/format-price';
import { formatDate, fromNow } from '@/library/format-date';

export default defineComponent({
  data() {
    return {
      formatPrice,
      formatDate,
      fromNow,
      shopStore,
      searchFocused: false,
      form: new Form({
        name: '',
        order_by: 'rating',
      }),
      paginator: null as Paginator<ShopProduct>|null,
      latestSearches: [] as { name: string; date: string }[],
    }
  },
  computed: {
    showResults() {
      return this.searchFocused 
          && ((this.paginator && this.form.data.name.length > 0) || this.latestSearches.length > 0);
    },
    
    noResults() {
      return this.form.data.name.length > 0 
          && this.filteredCategories.length === 0
          && this.filteredManufacturers.length === 0
          && !this.paginator?.data.length
    },
    
    categories() {
      return shopStore.getState().categories;
    },
    
    filteredCategories() {
      if (this.form.data.name.length === 0) return [];
      
      const categories: ShopCategory[] = [];
      
      const addIfMatch = (category: ShopCategory) => {
        if (category.name.toLowerCase().includes(this.form.data.name.toLowerCase())) {
          categories.push(category);
        }
      }
      
      this.categories.forEach(category => {
        addIfMatch(category);
        
        category.children.forEach(addIfMatch);
      })
      
      return categories.slice(0, 3);
    },

    manufacturers() {
      return shopStore.getState().manufacturers;
    },
    
    filteredManufacturers() {
      if (this.form.data.name.length === 0) return [];
      
      return this.manufacturers
          .filter(m => m.name.toLowerCase().includes(this.form.data.name.toLowerCase()))
          .slice(0, 3);
    }
  },
  created() {
    this.setLatestSearches();
  },
  methods: {
    search: debounce(function(this: any) {
      this.searchDebounced();
    }, 250),
    
    searchDebounced() {
      this.form.get(window.route('shopProducts.index'))
          .then(({ paginator }) => {
            this.paginator = paginator;
          });
    },

    setLatestSearches(): void {
      this.latestSearches = JSON.parse(localStorage.getItem('latestSearchesShop') ?? '[]');
    },
    
    addLatestSearch(name: string) {
      const latestSearches = [{ name, date: new Date() }, ...this.latestSearches.filter(s => s.name !== name)];
      localStorage.setItem('latestSearchesShop', JSON.stringify(latestSearches));
    },
  },
})
</script>
