const isCurrentRoute = (routes: Array<string>|string, absolute = false): boolean => {
  if (typeof routes === 'string') {
    return isCurrentRouteCheck(routes, absolute);
  }

  for (let i = 0; i < routes.length; i++) {
    if (isCurrentRouteCheck(routes[i], absolute)) {
      return true;
    }
  }

  return false;
}

const isCurrentRouteCheck = (route: string, absolute: boolean): boolean => {
  return absolute
    ? window.location.toString() === route
    : window.route().current() === route;
}

export default isCurrentRoute;
