<template>
  <span ref="item" class="flex-shrink-0 relative mx-0.5" :class="{ 'hidden lg:inline': category.order > 4 && category.order < 7, 'hidden xl:inline': category.order >= 7 }">
    <button 
      :id="`menu-item-${category.id}`" 
      class="py-2 px-6 xl:px-8 text-sm md:text-base text-white font-medium rounded hover:underline" 
      :class="{ 'underline bg-green-700': isOpen, 'bg-green-700 text-white': isCurrentCategory(category) }"
      @click="toggleOpen"
    >
      {{ category.name }}
      <fa-icon :icon="isOpen ? 'chevron-up' : 'chevron-down'" class="ml-2 text-green-300 fa-sm pointer-events-none" />
    </button>
  
    <transition name="fade">
      <div
        v-if="isOpen"
        v-click-outside="onClickOutside"
        class="absolute mt-2 z-20 top-full left-1/2 transform w-64 rounded-b-md p-1.5 border-b border-r border-l border-gray-300 bg-white flex flex-col"
        :style="{ transform: `translateX(${transformLeft})` }"
      >
        <a 
          :href="window.route('shopCategories.show', { category: category.slug })"
          class="px-3 py-1.5 text-black hover:underline"
          :class="{ 'underline': isCurrentCategory(category) }"
        >
          Visa alla
        </a>
        
        <a
          v-for="child in category.children"
          :key="child.id"
          :href="window.route('shopCategories.show', { category: child.slug })"
          class="px-3 py-1.5 text-black hover:underline"
        >
          {{ child.name }}
        </a>
        
        <a :href="window.route('shopCategories.show', { category: category.slug, campaigns: true })" class="px-3 py-1.5 font-bold text-red-600 hover:underline">
          <span class="mr-2 bg-red-100 text-red-600 px-2 py-0.5 rounded-md">%</span>
          Kampanjer
        </a>
      </div>
    </transition>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ShopCategory } from "@/@types";
import isCurrentCategory from '@/library/is-current-category';

export default defineComponent({
  props: {
    category: {
      type: Object as PropType<ShopCategory>,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      transformLeft: '0px',
    }
  },
  mounted() {
    this.calculateTransformLeft();
  },
  methods: {
    isCurrentCategory,
    
    calculateTransformLeft() {
      const fromLeft = this.$refs.item.offsetLeft;
      const halfWidth = this.$refs.item.clientWidth / 2;

      this.transformLeft = fromLeft - halfWidth < 0
          ? `-${fromLeft + halfWidth}px`
          : '-50%';
    },
    
    toggleOpen() {
      this.calculateTransformLeft();
      this.isOpen = !this.isOpen;
    },
    
    onClickOutside(event: any) {
      this.isOpen = event.target.id === `menu-item-${this.category.id}` || event.target.parentElement .id === `menu-item-${this.category.id}`;
    },
  },
})
</script>
