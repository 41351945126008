import { ShopCategory } from "@/@types";
import isCurrentRoute from "@/library/is-current-route";

const isCurrentCategory = (category: ShopCategory): boolean => {
  if (category.children) {
    return isCurrentRoute(window.route('shopCategories.show', { category: category.slug }), true)
      || isCurrentRoute(category.children.map(c => window.route('shopCategories.show', { category: c.slug })), true)
  } else {
    return isCurrentRoute(window.route('shopCategories.show', { category: category.slug }), true)
  }
}

export default isCurrentCategory;
