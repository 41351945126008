<template>
  <a 
    :href="window.route('shopProducts.show', { product: product.slug })"
    class="bg-white flex flex-col sm:border border-gray-300 sm:rounded-md hover:border-gray-400 group"
  >
    <div class="w-full aspect-square max-h-[12rem] relative sm:rounded-md overflow-hidden">
      <div 
        v-if="hasDiscount"
        class="absolute z-10 top-2 left-2 py-0.5 px-1.5 rounded-md bg-red-100 text-xs lg:text-sm"
      >
        <fa-icon icon="badge-percent" type="fas" class="text-red-500 mr-1" />
        <span class="font-medium text-red-900">-{{ formatPrice(product.latest_discount, false, 0) }}%</span>
      </div>
      
      <div 
        v-if="!small && hasReviews"
        class="absolute z-10 top-2 right-2 py-0.5 px-1.5 rounded-md bg-star-light text-xs lg:text-sm flex items-center"
      >
        <span class="font-medium text-yellow-1000">{{ formatPrice(product.reviews_rating, false, 1, true) }}</span>
        <fa-icon icon="star" type="fas" class="text-star-dark ml-1" />
      </div>
      
      <img :src="product.image_url" :alt="product.name" class="bg-white w-full h-full object-contain" loading="lazy">
    </div>
    
    <div v-if="!small" class="flex-1 flex flex-col justify-between p-2 lg:p-3">
      <div class="text-black font-medium text-sm leading-tight group-hover:underline" v-html="product.name" />
      
      <div class="text-gray-600 text-sm leading-tight">
        {{ product.category_name }}
      </div>
      
      <div class="flex-1" />
      
      <div class="flex items-center mt-2">
        <div class="font-bold text-lg" :class="hasDiscount ? 'text-red-600' : 'text-black'">
          {{ formatPrice(product.latest_price, true, 2) }}
        </div>
        
        <div v-if="hasDiscount" class="ml-2 text-sm text-gray-500 line-through">
          {{ formatPrice(product.default_price, true, 2) }}
        </div>
      </div>
      
      <div class="mt-1 flex items-center">
        <span class="h-2 w-2 rounded-full mr-2" :class="product.in_stock ? 'bg-green-500' : 'bg-red-500'" />
        <div class="text-xs text-gray-600">Finns {{ product.in_stock ? 'i' : 'inte i' }} lager</div>
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import shopProductMixin from "@/library/shop-product-mixin";
import FaIcon from "@/components/FaIcon.vue";

export default defineComponent({
  components: { FaIcon },
  mixins: [shopProductMixin],
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
