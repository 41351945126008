import axios, { Canceler } from 'axios';
import { Method } from 'axios';
import Errors from "@/library/errors";
import toastStore from "@/store/toast-store";
import { serialize } from "object-to-formdata";
import Bugsnag from "@bugsnag/js";

const CancelToken = axios.CancelToken;

type DataCallback = (data: IObjectKeys) => IObjectKeys;

export const transformValueFromRequest = (val: any): any => {
  if (typeof val === "boolean") {
    return val;
  } else if (val === null) {
    return '';
  } else if (typeof val === 'object') {
    const isArray = Array.isArray(val);
    const newObject: any = isArray ? [] : {};

    Object.keys(val).forEach(function(key) {
      const newVal = transformValueFromRequest(val[key]);
      
      if (isArray) {
        newObject.push(newVal);
      } else {
        newObject[key] = newVal;
      }
    });
    
    return newObject;
  } else {
    return Number(val) || val;
  }
};

class Form {
  errors = new Errors;
  isProcessing = false;
  progress = 0;
  data: IObjectKeys = {};
  private keys: string[] = [];
  private temporarySubmitData: IObjectKeys = {};
  private dataCallback: DataCallback = (d) => d;
  private cancelProcess: Canceler|null = null;
  private readonly cancelOnMultiple: boolean = true;
  private readonly useFormData: boolean = true;
  private readonly onProgress: ((progress: number) => void)|null = null;

  constructor(data: IObjectKeys|null = {}, cancelOnMultiple = false, useFormData = false, onProgress: ((progress: number) => void)|null = null) {
    if (data) {
      this.setData(data);
    }

    this.cancelOnMultiple = cancelOnMultiple;
    this.useFormData = useFormData;
    this.onProgress = onProgress;
  }

  setData(data: IObjectKeys): Form {
    this.keys = Object.keys(data);
    this.data = data;
    return this;
  }

  setDataFromObject(data: IObjectKeys): Form {
    if (this.keys.length == 0) throw 'No data keys set.';

    for (const field of this.keys) {
      if (data.hasOwnProperty(field)) {
        this.data[field] = transformValueFromRequest(data[field]);
        console.log("FORM: " + field + " => ", this.data[field]);
      }
    }
    return this;
  }

  withSubmitData(data: IObjectKeys): Form {
    for (const field in data) {
      this.temporarySubmitData[field] = data[field];
    }
    return this;
  }
  
  withData(dataCallback: DataCallback): Form {
    this.dataCallback = dataCallback;
    return this;
  }

  reset(): void {
    for (const field of this.keys) {
      this.data[field] = '';
    }

    this.errors.clear();
  }

  get<ReturnData = IObjectKeys>(url: string): Promise<ReturnData> {
    return this.submit('get', url);
  }

  post<ReturnData = IObjectKeys>(url: string): Promise<ReturnData> {
    return this.submit('post', url);
  }

  put<ReturnData = IObjectKeys>(url: string): Promise<ReturnData> {
    return this.submit('put', url);
  }

  patch<ReturnData = IObjectKeys>(url: string): Promise<ReturnData> {
    return this.submit('patch', url);
  }

  delete<ReturnData = IObjectKeys>(url: string): Promise<ReturnData> {
    return this.submit('delete', url);
  }

  async submit<ReturnData = IObjectKeys>(method: Method, url: string): Promise<ReturnData> {
    if (this.cancelOnMultiple && this.isProcessing) {
      this.cancel();
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    return this.sendRequest<ReturnData>(method, url);
  }

  sendRequest<ReturnData>(method: Method, url: string): Promise<ReturnData> {
    this.isProcessing = true;
    this.progress = 0;

    const data = this.formatData();

    console.log("submit", method, url, data);

    return new Promise((resolve, reject) => {
      axios.request({
        method,
        url,
        data: method === 'get' ? null : data,
        params: method === 'get' ? data : null,
        cancelToken: new CancelToken((c) => {
          this.cancelProcess = c;
        }),
        onUploadProgress: (progressEvent: ProgressEvent) => {
          this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (this.onProgress) this.onProgress(this.progress);
        },
      }).then(response => {
        this.onSuccess();
        resolve(response.data);
      }).catch(error => {
        if (axios.isCancel(error)) {
          reject({ cancelled: true });
        } else if (!error.hasOwnProperty('response') || !error.response) {
          console.log(error);
          Bugsnag.notify(error);
          reject({ message: 'Okänt fel' });
        } else if (!error.response.hasOwnProperty('status')) {
          console.log(error.response);
          Bugsnag.notify(error.response);
          reject({ message: 'Okänt fel' });
        } else if (error.response.status === 419) {
          toastStore.error('Du har varit inaktiv för lång tid. Var god uppdatera hemsidan.')
          reject(error.response);
        } else if (error.response.status === 424) {
          reject(error.response);
        } else if (error.response.status === 500) {
          try {
            console.log(error.response.data.message);
          } catch (e) {}
          
          reject(error.response);
        } else {
          this.errors.record(error.response.data);
          reject(error.response.data);
        }
      }).finally(() => {
        this.isProcessing = false;
        this.cancelProcess = null;
        this.temporarySubmitData = [];
      })
    });
  }

  formatData(): IObjectKeys {
    const data = { ...this.dataCallback(this.data), ...this.temporarySubmitData };

    if (this.useFormData) {
      return serialize(data, { indices: true, booleansAsIntegers: true, allowEmptyArrays: true })
    } else {
      return data
    }
  }

  toUrlParams(): string {
    return (new URLSearchParams(this.data)).toString();
  }

  cancel(): void {
    if (this.cancelProcess) {
      this.cancelProcess();
    }
  }

  onSuccess(): void {
    this.errors.clear();
  }
}

export default Form
