<template>
  <font-awesome-icon :key="icon+type" :icon="[type, icon]" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default defineComponent({
  components: { FontAwesomeIcon },
  props: {
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'far',
      validator: (val: string) => ['fas', 'far', 'fab', 'fal'].includes(val),
    },
  },
});
</script>
