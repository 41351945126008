import Layout from "@/layout/Layout.vue";
import ShopLayout from "@/layout/ShopLayout.vue";
import KennelProfileLayout from "@/layout/KennelProfileLayout.vue";
import { setup } from "@/setup";

async function resolvePageComponent(name: string) {
  // TODO: improve this function
  // https://github.com/vitejs/vite/issues/4945#issuecomment-951927975
  // import.meta.glob('./pages/**/*.vue') resulterar i mkt större chunks
  const splitName = name.split('/');

  if (splitName.length === 1) {
    return (await import(`./pages/${splitName[0]}.vue`));
  }
  if (splitName.length === 2) {
    return (await import(`./pages/${splitName[0]}/${splitName[1]}.vue`));
  }
  if (splitName.length === 3) {
    return (await import(`./pages/${splitName[0]}/${splitName[1]}/${splitName[2]}.vue`));
  }
  if (splitName.length === 4) {
    return (await import(`./pages/${splitName[0]}/${splitName[1]}/${splitName[2]}/${splitName[3]}.vue`));
  }
}

setup(
  (name: string) => {
    if (name.startsWith('Kennels/KennelsShow')) {
      return KennelProfileLayout
    } else if (name.startsWith('Shop/')) {
      return ShopLayout
    } else {
      return Layout
    }
  },
  resolvePageComponent,
);
