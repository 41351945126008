<template>
  <div class="flex items-center" :class="{ 'mt-3 sm:mt-6': margin, 'justify-center': centered }">
    <component
      :is="headingComponent"
      class="py-1 font-bold xl:px-0"
      :class="[{ 'px-2': padding }, white ? 'text-white' : 'text-gray-900', h1 ? 'text-2xl md:text-3xl lg:text-4xl' : 'text-xl md:text-2xl lg:text-3xl']"
    >
      <slot />
    </component>

    <a v-if="url" :href="url" class="font-hairline ml-2 p-1 text-sm text-gray-600 hover:text-gray-900 hover:underline lg:text-lg">
      {{ subtitle }}
      <fa-icon icon="chevron-right" class="fa-sm ml-1" />
    </a>

    <span v-else-if="subtitle" class="font-hairline ml-2 p-1 text-sm text-gray-600 lg:text-lg">
      {{ subtitle }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    subtitle: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: Boolean,
      default: true,
    },
    h1: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headingComponent(): string {
      return this.h1 ? 'h1' : 'h2';
    },
  },
})
</script>
