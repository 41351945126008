<template>
  <div
    class="z-100 fixed top-1/2 w-full sm:w-auto sm:left-1/2 p-2 sm:p-3 flex flex-col items-center sm:items-end transform -translate-y-1/2 sm:-translate-x-1/2"
    :class="{ 'pointer-events-none': state.toasts.length === 0 }"
  >
    <transition-group name="fade">
      <button
        v-for="toast in state.toasts"
        :key="toast.id"
        type="button"
        class="flex items-center justify-between text-left rounded border p-2 text-sm font-medium max-w-xs mb-2 shadow-sm sm:text-base sm:p-3"
        :class="{
          'bg-white text-gray-700 border-gray-300': toast.type === ModalType.None,
          'bg-green-500 text-white border-green-600': toast.type === ModalType.Success,
          'bg-red-500 text-white border-red-600': toast.type === ModalType.Danger,
        }"
        @click="remove(toast.id)"
      >
        <span class="mr-2 sm:mr-3">{{ toast.text }}</span>
        <fa-icon icon="times" class="opacity-50" />
      </button>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import toastStore from "@/store/toast-store";
import { ModalType } from '@/@types';

export default defineComponent({
  data() {
    return {
      state: toastStore.getState(),
      ModalType,
    }
  },
  methods: {
    remove(id: number): void {
      toastStore.removeToast(id);
    },
  },
});
</script>
