import {
  formatDistanceToNowStrict,
  parseISO,
  formatRelative,
  format,
  differenceInYears,
  differenceInMonths,
  differenceInWeeks,
  differenceInDays,
} from 'date-fns/esm'
import { sv } from 'date-fns/esm/locale'

export const parseDate = (date: string): Date => {
  if (! date.slice(0, 8).includes('-')) {
    date = date.slice(0, 8);
  }

  return parseISO(date.replace(' ', 'T') + 'Z');
}

export function fromNow(date: string, addSuffix = false): string {
  if (! date) {
    return '-';
  }

  return formatDistanceToNowStrict(parseDate(date), { addSuffix, locale: sv });
}

export function formatDate(date: string): string {
  if (! date) {
    return '-';
  }

  return formatRelative(parseDate(date), Date.now(), { locale: sv });
}

export function formatDateExplicit(date: string|Date, outputFormat = 'EEEE do LLLL'): string {
  return format(
    typeof date === 'string' ? parseDate(date) : date,
    outputFormat,
    { locale: sv }
  )
}

export function formatDateAsAge(temp: string, showSuffix = false): string {
  const now = new Date();
  const birth_date = parseDate(temp);
  const suffix = showSuffix ? ' gammal' : '';

  const years = differenceInYears(now, birth_date);
  const months = differenceInMonths(now, birth_date);
  const weeks = differenceInWeeks(now, birth_date);
  const days = differenceInDays(now, birth_date);
  
  if (days < 0) {
    return `Föds ${temp}`;
  } else if (years >= 2) {
    return `${years} år ${suffix}`;
  } else if (weeks < 2) {
    return `${days} dagar ${suffix}`;
  } else if (months <= 2) {
    return `${weeks} veckor ${suffix}`;
  } else {
    return `${months} mån ${suffix}`;
  }
}
