<template>
  <transition name="fade-in">
    <div
      v-if="hasError"
      class="bg-red-50 text-red-500 mt-2 px-2 py-1 font-medium"
      :class="xs ? 'text-xs truncate max-w-xs' : 'rounded text-sm'"
    >
      {{ errorText }}
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Errors from "@/library/errors";

export default defineComponent({
  props: {
    errors: {
      type: Object as PropType<Errors|any>,
      required: true,
    },
    field: {
      type: [String, Array] as PropType<string|string[]>,
      default: null,
    },
    any: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasError(): boolean {
      return !! this.errorText;
    },

    errorText(): string|null {
      return this.any ? this.errors.allAsText() : this.errors.get(this.field);
    },
  },
})
</script>
