
export enum ModalType {
  None,
  Success,
  Danger,
}

export enum ConversationStatus {
  Pending = 0,
  DeniedBySeller = 2,
  CancelledByBuyer = 3,
}

export enum Gender {
  Male,
  Female,
}

export enum Size {
  Tiny = 1,
  Small,
  Medium,
  Large,
  Huge,
}

export enum DogAge {
  Puppy,
  Young,
  Adult,
}

export enum TraitAbility {
  VeryLow = 1,
  Low,
  Medium,
  High,
  VeryHigh,
}

export enum Seller {
  Individual,
  Kennel,
  Organization
}

export enum AdVerificationStatus {
  Pending,
  Verified,
  Denied,
  DocumentsRequired,
}

export enum FoodProductSpecialty {
  None = 0,
  Joints = 1,
  Overweight = 2,
  Digestion = 3,
  Itching = 4,
  Sterilized = 5,
}

export enum FoodProductLifestyle {
  Normal = 0,
  Active = 1,
}

export enum FoodProductType {
  Dry = 0,
  Wet = 1,
}

export enum ContestEntryType {
  Default,
  FacebookLike,
  InstagramLike,
  AppInstallation,
  FriendReferral,
}

export enum KennelTier {
  Beginner,
  Premium,
  Expert,
}

export enum BreedMediaState {
  Normal,
  Hidden,
  Promoted,
}

export enum InsuranceCompany {
  Ingen = 0,
  Folksam = 1,
  Svedea = 2,
  TryggHansa = 3,
  If = 4,
  Hedvig = 5,
  ManyPets = 6,
  Dina = 7,
  Lassie = 8,
  ICA = 9,
  Sveland = 10,
  Agria = 11,
  Petson = 12,
}

export enum InsuranceInterest {
  NoInterest,
  NoAlreadyHas,
  Yes,
  CallLater,
}
