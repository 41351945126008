<template>
  <input
    id="email"
    :value="modelValue"
    type="text"
    name="email"
    autocomplete="email"
    :class="inputClass"
    @input="onInput"
  >

  <div
    v-if="looksLikeProvider"
    class="rounded p-1 text-xs mt-1 bg-orange-100 text-orange-600"
  >
    Har du skrivit fel e-post? Din e-post liknar <strong>{{ looksLikeProvider }}</strong>.
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { debounce } from "lodash-es";
import stringSimilarity from "@/library/string-similarity";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      emailProviders: ['hotmail.com', 'hotmail.se', 'yahoo.com', 'gmail.com', 'live.com', 'live.se', 'outlook.com', 'icloud.com'],
      looksLikeProvider: null as string|null,
      looksLikeSimilarity: 0,
    }
  },
  methods: {
    onInput(event: HTMLInputEvent): void {
      this.$emit('update:modelValue', event.target.value);
      this.debounceCheckEmailProvider();
    },

    debounceCheckEmailProvider: debounce(function(this: any) {
      this.looksLikeProvider = null;
      this.looksLikeSimilarity = 0;
      const [,current] = this.modelValue.split('@');

      if (! current) return;

      this.emailProviders.every((emailProvider: string) => {
        const similarity = stringSimilarity(emailProvider, current);

        if (similarity === 1) {
          this.looksLikeProvider = null;
          this.looksLikeSimilarity = 0;
          return false;
        } else if (similarity >= 0.7 && similarity > this.looksLikeSimilarity) {
          this.looksLikeProvider = emailProvider;
          this.looksLikeSimilarity = similarity;
        }

        return true;
      })
    }, 500),
  },
});
</script>
