export default function formatPrice(price: number, addSuffix = true, maximumFractionDigits = 2, trailingZeroDisplay = false): string {
  let formatted: string;

  try {
    formatted = new Intl.NumberFormat('sv-SE', {
      currency: 'SEK',
      style: addSuffix ? 'currency' : 'decimal',
      // maximumSignificantDigits: 5,
      minimumFractionDigits: maximumFractionDigits,
      maximumFractionDigits,
      trailingZeroDisplay: trailingZeroDisplay ? "auto" : "stripIfInteger",
    } as any).format(price);
  } catch (e) {
    formatted = '-';
  }

  return formatted;
}
