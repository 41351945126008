<template>
  <section class="mt-6 bg-green-600 sm:mt-8 lg:mt-12">
    <div class="mx-auto max-w-screen-xl items-center p-4 sm:flex lg:p-6 xl:px-0 xl:py-10">
      <div class="max-w-2xl lg:flex">
        <div class="hidden shrink-0 sm:block lg:mx-6">
          <div class="flex size-10 items-center justify-center rounded-full bg-yellow-500 text-yellow-900 lg:size-12 lg:text-xl">
            <fa-icon icon="envelope" />
          </div>
        </div>
          
        <main class="flex-1 sm:mr-6 sm:mt-3 lg:mt-0">
          <h2 class="text-lg font-bold leading-none text-white md:text-xl lg:text-2xl">
            Få smarta shoppingtips i vårt nyhetsbrev!
          </h2>
        
          <h3 class="mt-1 text-sm text-green-200 md:text-base">
            Vi handplockar det bästa inom produktnyheter, trender och butikernas deals - så att du snabbt och enkelt får koll på det senaste.
          </h3>
          
          <template v-if="! hasSubscribed">
            <form class="relative mt-4 w-full max-w-md lg:mt-8" @submit.prevent="submit">
              <input
                v-model="form.data.email"
                type="email"
                placeholder="Ange din e-post"
                class="form-element form-element-lg border-0"
                @input="form.errors.clear()"
              >

              <button
                type="submit"
                class="absolute right-0 top-0 z-10 h-full p-2 text-black"
              >
                <fa-icon icon="arrow-right" />
              </button>
            </form>

            <form-error :errors="form.errors" :any="true" />
          </template>

          <div v-else class="mt-6 rounded-md bg-green-100 p-2 text-center text-sm text-green-1000 sm:text-base xl:text-lg">
            <fa-icon icon="check" class="mr-2" />
            Tack, du prenumererar nu på vårat nyhetsbrev.
          </div>
        </main>
      </div>
      
      <div class="flex-1" />
      
      <aside class="-m-4 mt-4 grid grid-cols-4 gap-1 bg-white p-2 sm:m-0 sm:bg-transparent sm:p-0 lg:max-w-lg lg:grid-cols-5">
        <product-card
          v-for="(product, n) in state.popularProducts"
          :key="product.id"
          :product="product"
          :small="true"
          class="overflow-hidden hover:border-white hover:ring-2 hover:ring-yellow-500 sm:border-transparent md:rounded-md"
          :class="{ 
            'hidden lg:block': n >= 8
          }"
        />
      </aside>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Form from "@/library/form";
import FormError from "@/components/FormError.vue";
import FaIcon from "@/components/FaIcon.vue";
import shopStore from "@/store/shop-store";
import ProductCard from "@/components/Shop/ProductCard.vue";

export default defineComponent({
  components: { ProductCard, FaIcon, FormError },
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: new Form({
        email: '',
        type: 'shop',
      }),
      hasSubscribed: false,
    }
  },
  computed: {
    state() {
      return shopStore.getState();
    },
  },
  methods: {
    submit(): void {
      this.form.post(window.route('newsletterSubscribers.store'))
        .then(() => {
          this.hasSubscribed = true;
        })
    },
  }
});
</script>
