import { DirectiveBinding } from "@vue/runtime-core";

interface ClickOutsideElement extends HTMLElement {
  __vueClickEventHandler__: any
}

interface ClickOutsideBinding extends DirectiveBinding {
  value: Function,
}

export default {
  beforeMount: (el: ClickOutsideElement, binding: DirectiveBinding): void => {
    const onClickOutside = binding.value;
    
    const ourClickEventHandler = async (event: MouseEvent) => {
      return new Promise<void>((resolve) => {
        const clickedEl = event.target as HTMLElement;
        
        if (el.contains(clickedEl) || el === clickedEl) {
          return;
        }
        
        const hasParent = (parentEl: HTMLElement): boolean => {
          return parentEl.isEqualNode(el) || (!!parentEl.parentElement && hasParent(parentEl.parentElement));
        }
  
        if (! hasParent(clickedEl )) {
          onClickOutside(event);
        }

        resolve();
      })
    };

    el.__vueClickEventHandler__ = ourClickEventHandler;

    document.addEventListener("click", ourClickEventHandler);
  },
  unmounted: (el: ClickOutsideElement): void => {
    document.removeEventListener("click", el.__vueClickEventHandler__);
  }
}
