import { defineComponent, PropType } from "vue";
import { ShopProduct } from "@/@types";
import formatPrice from "@/library/format-price";

export default defineComponent({
  props: {
    product: {
      type: Object as PropType<ShopProduct>,
      required: true,
    },
  },
  computed: {
    description(): string {
      return this.product.vendorProducts.find(vp => vp.description.length > 0)?.description ?? 'Ingen beskrivning';
    },

    hasDiscount(): boolean {
      return this.product.latest_discount > 0;
    },

    hasReviews(): boolean {
      return !!this.product.reviews_count && this.product.reviews_count > 0;
    },
    
    groupId() {
      return this.product.vendorProducts.find(vp => vp.group_id)?.group_id;
    },
  },
  methods: {
    formatPrice,
  },
})
