<template>
  <modal
    v-if="show"
    :initial-open="true"
    :should-close-on-click-outside="true"
    cancel-text="Stäng"
  >
    <div class="flex flex-col">
      <img class="h-10 w-auto" src="@assets/Logo-Green-Horizontal.svg" alt="Skaffa Hund Logo">
      
      <page-title :centered="true">
        Köp och sälj hundar tryggt.
      </page-title>
      
      <p class="text-center lg:text-lg">
        Vi har tryggt förmedlat över <strong>70 000</strong> hundar. Låt oss hjälpa dig hitta din nya familjemedlem också.
      </p>
      
      <div class="mt-6 flex flex-col items-center justify-center space-y-3">
        <a :href="window.route('information.buyer')" class="btn-green w-full rounded-full">
          Kom igång med ditt hundköp
          <fa-icon icon="chevron-right" class="ml-3" />
        </a>
        
        <a :href="window.route('information.seller')" class="btn-white w-full rounded-full">
          Jag planerar att sälja en hund
          <fa-icon icon="chevron-right" class="ml-3" />
        </a>
      </div>
    </div>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ModalType } from "@/@types";
import Modal from "@/components/Modal.vue";
import PageTitle from "@/components/PageTitle.vue";
import { differenceInDays } from "date-fns/esm";

export default defineComponent({
  components: { PageTitle, Modal },
  data() {
    return {
      show: false,
      ModalType,
    }
  },
  computed: {
    enabledForUrl() {
      return ! [
        'information.buyer',
        'information.seller',
        'login',
      ].includes(window.route().current()!);
    }
  },
  mounted() {
    if (! this.isLogged && this.enabledForUrl) {
      setTimeout(this.showModalIfNotSeen, 5000);
    }
  },
  methods: {
    showModalIfNotSeen() {
      const lastSeen = localStorage.getItem('premiumInfoModalDate');
      
      if (! lastSeen) {
        return this.showModal();
      }

      const daysSinceLastSeen = differenceInDays(new Date(), new Date(lastSeen));
      
      if (daysSinceLastSeen > 31) {
        this.showModal();
      }
    },

    showModal() {
      this.show = true;
      localStorage.setItem('premiumInfoModalDate', (new Date).toString());
    }
  }
})
</script>
