import Store from "./store"
import { Paginator, ShopCategory, ShopManufacturer, ShopProduct } from "@/@types";
import Form, { transformValueFromRequest } from "@/library/form";
import { clone } from "@/library/clone";
import { debounce } from "lodash-es";

const DEFAULT_FILTERS = {
  name: '',
  categories: [],
  manufacturers: [],
  order_by: 'discount',
  campaigns: false,
  in_stock: false,
}

export type ProductFilterOption = keyof typeof DEFAULT_FILTERS;

export type ProductFilterOptions = { [key in ProductFilterOption]: any };

interface ShopStoreInterface extends Object {
  paginator: Paginator<ShopProduct>|null;
  categories: ShopCategory[];
  manufacturers: ShopManufacturer[];
  popularProducts: ShopProduct[];
  filters: ProductFilterOptions;
  manufacturersFilter: string,
  categoriesFilter: string,
  isLoading: boolean;
  filterExpanded: boolean;
  form: Form;
}

class ShopStore extends Store<ShopStoreInterface> {
  fetchResults = debounce(this.fetchResultsDebounced.bind(this), 250);
  
  setup(categories: ShopCategory[], manufacturers: ShopManufacturer[], popularProducts: ShopProduct[]): void {
    this.state.categories = categories;
    this.state.manufacturers = manufacturers;
    this.state.popularProducts = popularProducts;
  }

  toggleFilterExpanded() {
    this.state.filterExpanded = ! this.state.filterExpanded;
  }

  setPaginator(paginator: Paginator<ShopProduct>) {
    this.state.paginator = paginator;
  }
  
  setManufacturersFilter(manufacturersFilter: string) {
    this.state.manufacturersFilter = manufacturersFilter;
  }
  
  setCategoriesFilter(categoriesFilter: string) {
    this.state.categoriesFilter = categoriesFilter;
  }
  
  setFilters(filters: ProductFilterOptions) {
    console.log('setFilters', filters);
    
    Object.typedKeys(filters)
      .filter((key) => filters[key] && filters[key].length > 0)
      .forEach((key) => {
        shopStore.setFilter(
          key, 
          transformValueFromRequest(filters[key]),
          false
        );
      });
  }
  
  setFilter(key: ProductFilterOption, value: any, shouldFetchResults = true) {
    console.log(`setFilter: ${key}`, value);
    
    this.state.filters[key] = value;
    
    if (shouldFetchResults) {
      this.fetchResults();
    }
  }

  toggleArrayValue(array: 'manufacturers'|'categories', id: number, shouldFetchResults = true) {
    if (this.state.filters[array].includes(id)) {
      this.state.filters[array] = this.state.filters[array].filter((v: any) => v !== id)
    } else {
      this.state.filters[array].push(id);
    }

    console.log(`toggleArrayValue: ${array}`, this.state.filters[array]);

    if (shouldFetchResults) {
      this.fetchResults();
    }
  }

  fetchResultsDebounced() {
    this.updateUrl();
    
    this.state.form.get(window.route('shopProducts.index', this.getParams()))
      .then(({ paginator }) => {
        this.setPaginator(paginator)
      })
  }
  
  getParams() {
    const params: IObjectKeys = {};

    Object.typedKeys(this.state.filters)
      .filter(key => {
        return JSON.stringify(this.state.filters[key]) !== JSON.stringify(DEFAULT_FILTERS[key]);
      })
      .forEach(key => {
        params[key] = typeof this.state.filters[key] === 'object'
          ? Object.values(this.state.filters[key])
          : this.state.filters[key];
      })
    
    return params;
  }
  
  updateUrl() {
    window.history.pushState(null, '', window.route('shopProducts.index', this.getParams()));
  }
}

const shopStore: ShopStore = new ShopStore({
  categories: [],
  manufacturers: [],
  popularProducts: [],
  filters: clone(DEFAULT_FILTERS),
  manufacturersFilter: '',
  categoriesFilter: '',
  isLoading: false,
  filterExpanded: false,
  paginator: null,
  form: new Form,
})

export default shopStore
