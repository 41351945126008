import Store from "./store"
import { debounce } from "lodash-es";

interface LayoutStoreInterface extends Object {
  width: number;
  height: number;
}

class LayoutStore extends Store<LayoutStoreInterface> {
  setup(): void {
    window.addEventListener('resize', debounce(this.updateLayout.bind(this), 100));
    this.updateLayout();
  }

  setLayout(data: LayoutStoreInterface) {
    this.state = data;
  }

  updateLayout() {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    document.documentElement.style.setProperty('--app-width', `${window.innerWidth}px`);
    
    this.state.width = window.innerWidth;
    this.state.height = window.innerHeight;
  }
}

const layoutStore: LayoutStore = new LayoutStore({
  width: 0,
  height: 0,
})

export default layoutStore
