<template>
  <nav
    class="relative"
    :class="[transparent ? 'bg-green-600 sm:bg-transparent' : 'bg-green-600', mobileMenuOpen || searchOpen ? 'z-100' : 'z-50']"
  >
    <div class="relative mx-auto flex h-14 max-w-screen-xl items-center">
      <a :href="window.route('home')" class="block shrink-0 p-2" :class="hoverClass">
        <img class="size-10 lg:hidden" src="@assets/Logo-White-Icon.svg" alt="Skaffa Hund Logotyp">

        <div class="hidden h-10 w-28 lg:block">
          <img
            v-if="transparent"
            class="block size-auto"
            src="@assets/Logo-Green-Horizontal-Variant.svg"
            alt="Skaffa Hund Logotyp"
          >
          <img
            v-else
            class="block size-auto"
            src="@assets/Logo-White-Horizontal-Variant.svg"
            alt="Skaffa Hund Logotyp"
          >
        </div>
      </a>

      <!-- Mobile Menu -->
      <div class="flex h-full grow justify-between sm:hidden">
        <a
          :href="window.route('ads.index')"
          class="flex w-1/5 flex-col items-center justify-center p-1 leading-snug text-white"
          :class="{'bg-green-700': isCurrentRoute(['ads.index', 'ads.show', 'ads.breeds'])}"
        >
          <div class="text-lg"><fa-icon icon="search" /></div>
          <div class="mt-0.5 text-2xs font-medium opacity-75">Annonser</div>
        </a>
        <a
          :href="isLogged ? window.route('profiles.show') : window.route('information.buyer')"
          class="relative flex w-1/5 flex-col items-center justify-center p-1 leading-snug text-white"
          :class="{'bg-green-700': isCurrentRoute(['profiles.show', 'profiles.create', 'profiles.success', 'information.buyer'])}"
        >
          <div class="text-lg"><fa-icon icon="dog" /></div>
          <div class="mt-0.5 text-2xs font-medium opacity-75">Köp</div>
          <div v-if="unseenMatches" class="absolute right-0 top-0 z-10 m-1 flex size-4.5 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white">
            {{ unseenMatches }}
          </div>
        </a>
        <a
          :href="isLogged ? window.route('account.ads') : window.route('information.seller')"
          class="flex w-1/5 flex-col items-center justify-center p-1 leading-snug text-white"
          :class="{'bg-green-700': isCurrentRoute(['information.seller', 'kennels.create', 'kennels.edit', 'account.ads', 'ads.create', 'ads.edit', 'ads.verification.show', 'ads.payments', 'account.dogs', 'account.sales', 'sales.create', 'sales.edit', 'account.contracts', 'ads.upsell', 'insuranceInvites.create'])}"
        >
          <div class="text-lg"><fa-icon icon="plus-circle" /></div>
          <div class="mt-0.5 text-2xs font-medium opacity-75">Sälj</div>
        </a>
        <a
          :href="window.route('conversations.index')"
          class="relative flex w-1/5 flex-col items-center justify-center p-1 leading-snug text-white"
          :class="{'bg-green-700': isCurrentRoute(['conversations.index', 'conversations.show'])}"
        >
          <div class="text-lg"><fa-icon icon="comment-lines" /></div>
          <div class="mt-0.5 text-2xs font-medium opacity-75">Inkorg</div>
          <div v-if="unseenMessages" class="absolute right-0 top-0 z-10 m-1 flex size-4.5 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white">
            {{ unseenMessages }}
          </div>
        </a>
        <button
          v-if="mobileMenuOpen || searchOpen"
          type="button"
          class="flex w-1/5 flex-col items-center justify-center bg-white p-1 leading-snug text-black"
          @click.stop="() => { mobileMenuOpen = false; $refs.search.close() }"
        >
          <span class="text-lg"><fa-icon icon="times" /></span>
          <span class="mt-0.5 text-2xs font-medium opacity-75">Stäng</span>
        </button>
        <button
          v-else
          type="button"
          class="flex w-1/5 flex-col items-center justify-center bg-green-600 p-1 leading-snug text-white"
          @click.stop="mobileMenuOpen = true"
        >
          <span class="text-lg"><fa-icon icon="ellipsis-h" /></span>
          <span class="mt-0.5 text-2xs font-medium opacity-75">Mer</span>
        </button>
      </div>

      <!-- Desktop Menu -->
      <div class="hidden h-full grow items-center justify-between overflow-hidden sm:flex lg:ml-6">
        <div class="flex h-full items-stretch">
          <a
            :href="window.route('ads.index')"
            class="flex items-center px-4 text-sm font-medium text-white"
            :class="[hoverClass, { 'bg-green-700': isCurrentRoute(['ads.index', 'ads.show', 'ads.breeds']) }]"
          >
            Annonser
          </a>
          <a
            :href="isLogged ? window.route('profiles.show') : window.route('information.buyer')"
            class="relative flex items-center px-4 text-sm font-medium text-white"
            :class="[hoverClass, { 'bg-green-700': isCurrentRoute(['profiles.show', 'profiles.create', 'profiles.success', 'information.buyer']) }]"
          >
            Köp
            <div v-if="unseenMatches" class="absolute right-0 top-0 z-10 m-1 flex size-4.5 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white">
              {{ unseenMatches }}
            </div>
          </a>
          <a
            :href="isLogged ? window.route('account.ads') : window.route('information.seller')"
            class="flex items-center px-4 text-sm font-medium text-white"
            :class="[hoverClass, { 'bg-green-700': isCurrentRoute(['information.seller', 'kennels.create', 'kennels.edit', 'account.ads', 'ads.create', 'ads.edit', 'ads.verification.show', 'ads.payments', 'account.dogs', 'account.sales', 'sales.create', 'sales.edit', 'account.contracts', 'ads.upsell', 'insuranceInvites.create']) }]"
          >
            Sälj
          </a>
          <a
            :href="window.route('breeds.index')"
            class="flex items-center px-4 text-sm font-medium text-white"
            :class="[hoverClass, { 'bg-green-700': isCurrentRoute(['breeds.index', 'breeds.show', 'breeds.statistics', 'quizzes.index', 'quizzes.show', 'breedGroups.index', 'breedGroups.show']) }]"
          >
            Hundraser
          </a>
          <a
            :href="window.route('articles.index')"
            class="hidden items-center px-4 text-sm font-medium text-white md:flex"
            :class="[hoverClass, { 'bg-green-700': isCurrentRoute(['articles.show', 'articles.index', 'tags.show']) }]"
          >
            Artiklar
          </a>
          <a
            :href="window.route('help')"
            class="flex items-center px-4 text-sm font-medium text-white"
            :class="[hoverClass, { 'bg-green-700': isCurrentRoute(['help', 'contact']) }]"
          >
            Hjälp
          </a>
          <a
            :href="window.route('shop')"
            class="my-3 flex items-center rounded-lg bg-yellow-400 px-2.5 text-sm font-medium text-yellow-1000 hover:border-white hover:bg-yellow-500 lg:m-3 lg:px-4"
            target="_blank"
          >
            <fa-icon icon="shopping-bag" class="mr-2" />
            Butiken
          </a>
        </div>
        <div class="flex h-full w-40 shrink-0 justify-end">
          <button
            type="button"
            class="relative flex w-1/3 flex-col items-center justify-center p-1 leading-snug text-white"
            :class="[hoverClass, { 'bg-smoke-10': searchOpen && transparent, 'bg-green-700': searchOpen && ! transparent }]"
            @click.stop="() => { $refs.search.open(); mobileMenuOpen = false; }"
          >
            <div class="text-xl">
              <fa-icon icon="search" />
            </div>
            <div class="text-2xs opacity-75">
              Sök
            </div>
          </button>
          <a
            :href="window.route('conversations.index')"
            class="relative flex w-1/3 flex-col items-center justify-center p-1 leading-snug text-white"
            :class="[hoverClass, { 'bg-green-700': isCurrentRoute(['conversations.index', 'conversations.show']) }]"
          >
            <div class="text-xl"><fa-icon icon="comment-lines" /></div>
            <div class="text-2xs opacity-75">Inkorg</div>
            <div v-if="unseenMessages" class="absolute right-0 top-0 z-10 m-1 flex size-4.5 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white">
              {{ unseenMessages }}
            </div>
          </a>
          <a
            v-if="user"
            class="flex w-1/3 cursor-pointer flex-col items-center justify-center p-1 leading-snug text-white"
            :class="[hoverClass, { 'bg-smoke-10': mobileMenuOpen && transparent, 'bg-green-700': mobileMenuOpen && ! transparent }]"
            @click.stop="() => { mobileMenuOpen = ! mobileMenuOpen; $refs.search.close(); }"
          >
            <div class="text-xl">
              <img
                v-if="user.avatar"
                :src="user.avatar.thumbnail"
                :alt="user.first_name"
                class="my-1 size-5 rounded-full border border-white"
              >
              <fa-icon v-else icon="user-circle" />
            </div>
            
            <div class="w-full truncate text-center text-2xs opacity-75">
              {{ user.first_name.split(' ')[0] }}
            </div>
          </a>
          <a
            v-else
            :href="window.route('login')"
            class="flex w-1/2 cursor-pointer flex-col items-center justify-center p-1 leading-snug text-white"
            :class="[hoverClass, { 'bg-green-700': isCurrentRoute(['login']) }]"
          >
            <div class="text-xl"><fa-icon icon="user-circle" /></div>
            <div class="text-2xs opacity-75">Logga in</div>
          </a>
        </div>
      </div>
      
      <menu-search 
        ref="search"
        :transparent="transparent"
        @open="searchOpen = true"
        @close="searchOpen = false"
      />

      <!-- Dropdown -->
      <div
        v-click-outside="() => mobileMenuOpen = false"
        class="full-height absolute left-0 top-0 mt-14 w-full overflow-scroll overscroll-contain border-gray-400 bg-white shadow-lg sm:left-auto sm:right-0 sm:mr-4 sm:h-auto sm:w-48 sm:border sm:border-t-0 lg:mr-0"
        :class="[mobileMenuOpen ? 'block' : 'hidden', transparent ? 'sm:rounded-lg' : 'sm:rounded-b-lg']"
      >
        <div class="px-2 pb-3 pt-2 sm:hidden">
          <a
            v-if="false"
            :href="window.route('shop')"
            class="mt-1 inline-block rounded-md bg-yellow-400 px-3 py-2 font-medium text-yellow-1000 hover:bg-yellow-500 sm:text-sm"
            target="_blank"
          >
            <fa-icon icon="shopping-bag" class="mr-2" />
            Butiken: Hitta dom bästa priserna
          </a>
          <a
            class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm"
            @click.stop="() => {mobileMenuOpen = false; $refs.search.open()}"
          >
            Sök
          </a>
          <a :href="window.route('shop')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Butiken
          </a>
          <a :href="window.route('breeds.index')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Hundraser
          </a>
          <a :href="window.route('articles.index')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Artiklar
          </a>
          <a :href="window.route('help')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Hjälp
          </a>
          <a v-if="! user" :href="window.route('login')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Logga in
          </a>
        </div>

        <div v-if="user" class="px-2 pb-3 pt-2 sm:pt-0">
          <a :href="window.route('users.edit')">
            <div class="mt-0 flex items-center rounded-md border border-gray-300 p-2 hover:bg-gray-100 sm:-mx-2 sm:rounded-none sm:border-0 sm:p-3">
              <user-avatar :avatar="user.avatar" class="mr-2 w-10 sm:w-10" />
              <div>
                <div class="text-sm font-bold text-black">
                  {{ user.first_name }} {{ user.last_name }}
                </div>
                <div class="text-xs text-gray-700">
                  {{ user.identity_number }}
                </div>
              </div>
            </div>
          </a>

          <a v-if="kennel" :href="window.route('kennels.edit')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            {{ kennel.name }}
          </a>
          <a v-else :href="window.route('kennels.create')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Skapa kennel
          </a>
          
          <a :href="window.route('likes.index')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Sparade annonser
          </a>
          
          <a :href="window.route('payments.index')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Betalningar
          </a>
          <a :href="window.route('account.contracts')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Kontrakt
          </a>
          <a :href="window.route('users.edit')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Inställningar
          </a>
          <a :href="window.route('logout')" class="mt-1 block rounded-md px-3 py-2 font-medium text-gray-800 hover:bg-gray-200 hover:text-black sm:text-sm">
            Logga ut
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import isCurrentRoute from "@/library/is-current-route";
import { Kennel } from "@/@types";
import userStore from "@/store/user-store";
import FaIcon from "@/components/FaIcon.vue";
import MenuSearch from "@/layout/MenuSearch.vue";
import UserAvatar from "@/components/Profile/UserAvatar.vue";

export default defineComponent({
  components: { UserAvatar, MenuSearch, FaIcon },
  data() {
    return {
      mobileMenuOpen: false,
      searchOpen: false,
      currentPage: window.route().current(),
    };
  },
  computed: {
    store() {
      return userStore.getState();
    },

    transparent(): boolean {
      return ['home', 'partners', 'ownershipCost.show', 'breedGuideResults.create', 'breedGuideResults.show', 'quizzes.create'].includes(this.currentPage) 
        && ! this.$page.props.hasOwnProperty('status');
    },

    hoverClass(): string {
      return this.transparent ? 'hover:bg-smoke-10' : 'hover:bg-green-700';
    },

    kennel(): Kennel|null {
      return this.store.kennel;
    },

    unseenMessages(): number {
      return this.isLogged ? this.store.unseenMessages : 0;
    },

    initials(): string {
      return (this.user!.first_name || '')[0] + (this.user!.last_name || '')[0];
    },
    
    unseenMatches(): number {
      return this.isLogged ? this.store.unseenMatches : 0;
    },
  },
  methods: {
    isCurrentRoute,
  },
});
</script>
