<template>
  <picture>
    <source :media="fullscreen ? '(max-width: 639px)' : null" :srcset="webp" type="image/webp">
    <source :media="fullscreen ? '(max-width: 639px)' : null" :srcset="src" type="image/jpeg">
    <source v-if="fullscreen" media="(min-width: 640px)" :srcset="fullscreenWebp" type="image/webp">
    <source v-if="fullscreen" media="(min-width: 640px)" :srcset="fullscreen" type="image/jpeg">

    <img
      :src="src"
      :alt="alt"
      :class="imgClass"
      loading="lazy"
      @error="hasError = true"
    >
  </picture>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
    fullscreen: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    imgClass: {
      type: [Array, String],
      default: null,
    },
  },
  data() {
    return {
      hasError: false,
    }
  },
  computed: {
    webp(): string {
      return this.hasError ? this.src : this.src.replace('.jpg', '_webp.webp');
    },

    fullscreenWebp(): string {
      return this.hasError || ! this.fullscreen ? this.fullscreen : this.fullscreen.replace('.jpg', '_webp.webp');
    },
  },
});
</script>
