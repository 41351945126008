<template>
  <div class="px-4">
    <a :href="window.route('shopCategories.show', { category: category.slug })" class="hidden md:flex items-center px-1.5 pb-1.5 text-black text-lg font-bold hover:underline">
      {{ category.name }}
      <fa-icon icon="chevron-right" class="ml-3 text-gray-500 fa-xs" />
    </a>
    
    <a class="flex md:hidden items-center justify-between px-1.5 py-3 text-black border-b border-gray-200 font-medium" :class="{ 'font-bold': isOpen }" @click.stop="isOpen = ! isOpen">
      {{ category.name }}
      
      <fa-icon :icon="isOpen ? 'chevron-down' : 'chevron-right'" class="ml-2 text-gray-500 fa-sm" />
    </a>
         
    <div class="pl-6 md:pl-0" :class="{ 'hidden md:block': !isOpen }">
      <a
        :href="window.route('shopCategories.show', { category: category.slug })"
        class="md:hidden flex items-center px-1.5 py-3 text-gray-800 border-b border-gray-200 md:border-0 md:py-0.5 hover:underline"
      >
        Visa allt i {{ category.name }}
      </a>
      
      <a
        v-for="child in category.children"
        :key="child.id"
        :href="window.route('shopCategories.show', { category: child.slug })"
        class="flex items-center px-1.5 py-3 text-gray-800 border-b border-gray-200 md:border-0 md:py-0.5 hover:underline"
      >
        {{ child.name }}
      </a>
      
      <a
        :href="window.route('shopCategories.show', { category: category.slug, campaigns: true })"
        class="flex items-center px-1.5 py-3 text-red-500 font-bold border-b border-gray-200 md:border-0 md:py-0.5 hover:underline"
      >
        <small class="mr-2 bg-red-100 text-red-600 px-2 rounded-md">%</small>
        Kampanjer
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ShopCategory } from "@/@types";
import isCurrentCategory from '@/library/is-current-category';

export default defineComponent({
  props: {
    category: {
      type: Object as PropType<ShopCategory>,
      required: true,
    },
  },
  data() {
    return {
      isOpen: isCurrentCategory(this.category),
    }
  },
})
</script>
