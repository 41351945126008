<template>
  <transition name="fade">
    <div v-if="show" class="fixed z-99 bottom-0 left-0 p-1.5">
      <a
        href="https://furryframe.se" 
        class="block w-full max-w-xs p-1.5 rounded-md bg-white border border-gray-400 shadow hover:bg-gray-100"
        target="_blank"
      >
        <div class="flex rounded-md overflow-hidden relative">
          <img
            alt="Vattenfärg"
            class="bg-white flex-1 h-full w-20 object-cover"
            src="https://furryframe.se/cdn/shop/files/dcabe740-f494-4547-9006-052e5756dbdf-49-framed.png?v=1705063087&width=200"
          >
          <img
            alt="Svartvit"
            class="bg-white flex-1 h-full w-20 object-cover"
            src="https://furryframe.se/cdn/shop/files/cc929f0b-df56-431d-b7c5-77f9d0944516-11-framed.png?v=1705060613&width=200"
          >
          <img
            alt="Kunglighet"
            class="bg-white flex-1 h-full w-20 object-cover"
            src="https://furryframe.se/cdn/shop/files/007b0ebe-21a7-4991-913f-9487ad880383-7-framed.png?v=1704813502&width=200"
          >
          
          <div class="absolute bottom-0 left-0 m-1.5 px-1 rounded-md bg-white text-red-600 text-sm font-bold">
            från 695 kr
          </div>
        </div>
        
        <div class="flex items-center">
          <div class="p-1.5">
            <div class="font-bold">Personliga husdjursporträtt</div>
            <div class="text-xs text-gray-600">
              Förvandla dina bilder till unika porträtt som du stolt kan hänga upp på dina väggar.
            </div>
            
            <img
              src="https://furryframe.se/cdn/shop/files/416779833_3561949767454417_3244383288631377229_n.svg"
              alt="FurryFrame logotyp"
              class="mt-1.5 h-3 w-auto"
            >
          </div>
      
          <button 
            aria-label="close"
            class="p-2 text-gray-700 hover:text-black"
            @click="dismiss"
          >
            <fa-icon icon="times" />
          </button>
        </div>
      </a>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      show: false,
    }
  },
  computed: {
    enabledForUrl() {
      return ! window.route().current('ads.show');
    }
  },
  mounted() {
    this.show = ! localStorage.getItem('furryFrameBox') && this.enabledForUrl;
  },
  methods: {
    dismiss(e) {
      e.preventDefault();
      e.stopPropagation();
      
      localStorage.setItem('furryFrameBox', 'dismissed');
      this.show = false;
    }
  }
})
</script>
