import Store from "./store"
import { ModalType } from "@/@types";

let toastId = 0;

interface Toast {
  type: ModalType,
  text: string,
}

interface ToastStoreInterface extends Object {
  toasts: (Toast & { id: number })[],
}

class ToastStore extends Store<ToastStoreInterface> {
  addToast(toast: Toast, removeAfterDelay = 5000): void {
    const id = toastId++;
    this.state.toasts.push({ ...toast, id });

    setTimeout(() => {
      this.removeToast(id);
    }, removeAfterDelay);
  }

  removeToast(id: number): void {
    this.state.toasts = this.state.toasts.filter(toast => toast.id !== id)
  }

  success(text = 'Dina ändringar sparades.'): void {
    this.addToast({ type: ModalType.Success, text })
  }

  error(text = 'Kontrollera din uppgifter.'): void {
    this.addToast({ type: ModalType.Danger, text }, 10000)
  }
}

const toastStore: ToastStore = new ToastStore({
  toasts: [],
})

export default toastStore
